//@import "bootstrap";
//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/vendor/rfs";

// Brand colours
@import "brand";

$primary: $one-green-400;
$secondary: $one-grey-600;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
//  "success":    $success,
//  "info":       $info,
//  "warning":    $warning,
//  "danger":     $danger,
//  "light":      $light,
//  "dark":       $dark
);

// Default variable overrides
$btn-border-width: 2px;
$btn-border-radius: 22px;
$btn-padding-x: 20px;
$btn-padding-y: 10px;

$modal-backdrop-bg: #fff;
$modal-backdrop-opacity: 0.8;
$offcanvas-bg-color: rgba(255,255,255, 0.9);
$offcanvas-vertical-height: auto;

$navbar-padding-y: 16px;
$navbar-padding-y-lg: 0;
$navbar-nav-link-padding-x: 30px;

// Customise tabs
// scss-docs-start nav-tabs-css-vars
$nav-link-color: #{$one-grey-600};
//$nav-tabs-border-width: '1px';
//$nav-tabs-border-color: #{$one-grey-200};
$nav-tabs-border-radius: 0;
//$nav-tabs-link-hover-border-color: #{$one-green-400};
$nav-tabs-link-active-color: #{$one-grey-900};
$nav-tabs-link-active-bg: #{$one-grey-50};
$nav-tabs-link-active-border-color: transparent;//#{$one-green-400};

$accordion-bg: none;
$accordion-button-active-bg: none;
$accordion-border-color: #{$one-grey-200};
$accordion-button-active-color: #{$one-green-400};
$accordion-icon-color: #{$one-grey-900};
$accordion-icon-active-color: #{$one-green-400};
$accordion-button-icon:         url("data:image/svg+xml,<svg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='2.75' y='0.75' width='18.5' height='18.5' rx='9.25' stroke='#{$accordion-icon-color}' stroke-width='1.5'/><path d='M16.5 8.25L12 12.75L7.5 8.25' stroke='#{$accordion-icon-color}' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round'/></svg>") !default;
$accordion-button-active-icon:  url("data:image/svg+xml,<svg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='2.75' y='0.75' width='18.5' height='18.5' rx='7.25' stroke='#{$accordion-icon-active-color}' stroke-width='1.5'/><path d='M7.5 12.25L12 7.75L16.5 12.25' stroke='#{$accordion-icon-active-color}' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round'/></svg>") !default;
$accordion-icon-transform: transform scale(-1, -1);

//$table-bg: #fff;
$table-cell-padding-y:        1rem;
$table-cell-padding-x:        2rem;
$table-striped-color:         #fff;
//$table-striped-bg-factor:     .05;
$table-striped-bg:            #{$one-grey-50}; //rgba(var(--#{$prefix}emphasis-color-rgb), $table-striped-bg-factor);
$table-border-color:          #{$one-grey-200};

// Required
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "bootstrap/scss/images";

// Optional Bootstrap components here
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/helpers/position";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/";


.accordion-button {
    font-family: EuclidCircularA-Medium, Arial, sans-serif;
    font-weight: bold;

    &:not(.collapsed) {
        box-shadow: none;
        &::before {
            background-image: var(--#{$prefix}accordion-btn-active-icon);
            transform: var(--#{$prefix}accordion-btn-icon-transform);
        }
    }
    // Accordion icon
    &::before {
        flex-shrink: 0;
        width: 1.5rem; //var(--#{$prefix}accordion-btn-icon-width);
        height: var(--#{$prefix}accordion-btn-icon-width);
        //margin-left: -$accordion-padding-x;
        margin-right: 1rem;
        content: "";
        background-image: var(--#{$prefix}accordion-btn-icon);
        background-repeat: no-repeat;
        background-size: var(--#{$prefix}accordion-btn-icon-width);
        @include transition(var(--#{$prefix}accordion-btn-icon-transition));
    }

    &::after {
        content: unset;
    }
}

#products-subpage-accordion {
    .accordion-body {
        .nav-item {
            &.nav-previous {
                .nav-link {
                    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 15 25" fill="none"><path d="M13.3372,1.70996L1.28614,12.3724C1.23548,12.4173,1.19492,12.4725,1.16714,12.5343C1.13936,12.5961,1.125,12.6631,1.125,12.7308C1.125,12.7985,1.13936,12.8655,1.16714,12.9273C1.19492,12.9891,1.23548,13.0443,1.28614,13.0892L13.3372,23.7516" stroke="%235A6376" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    background-repeat: no-repeat;
                    background-position: 1.25rem center;
                    background-size: 15px 15px;
                }
            }
        }
    }
}
@include media-breakpoint-down(md) {
    #products-subpage-accordion {
        .accordion-body {
            .nav-item {
                .nav-link {
                    padding-left: 1.5rem + 1.25rem + 1rem;
                }
            }
        }
    }
}

// generate utilities
@import "bootstrap/scss/utilities/api";

// Brand fonts
@font-face {
    font-family: EuclidCircularA-Medium;
    src: url(../webfonts/EuclidCircularA-Medium-WebXL.woff) format("woff"), url(../webfonts/EuclidCircularA-Medium-WebXL.woff2) format("woff2");//, url(../webfonts/EuclidCircularA-Medium-WebS.ttf) format("truetype");
    font-weight: 400;
    font-style:normal
}

@font-face {
    font-family: EuclidCircularA-Regular;
    src: url(../webfonts/EuclidCircularA-Regular-WebXL.woff) format("woff"), url(../webfonts/EuclidCircularA-Regular-WebXL.woff2) format("woff2");//, url(../fowebfontsnts/EuclidCircularA-Regular-WebS.ttf) format("truetype");
    font-weight: 400;
    font-style:normal
}

@font-face {
    font-family: EuclidCircularA-Light;
    src: url(../webfonts/EuclidCircularA-Light-WebXL.woff) format("woff"), url(../webfonts/EuclidCircularA-Light-WebXL.woff2) format("woff2");//, url(../webfonts/EuclidCircularA-Light-WebS.ttf) format("truetype");
    font-weight: 400;
    font-style:normal
}

.nav-tabs {
    --bs-nav-tabs-link-hover-border-color: transparent;
    font-size: 22px;
    .nav-link {
        padding: 10px 18px;
        color: #{$one-grey-600};
        &.active {
            border-bottom: 3px solid #{$one-green-600};
        }
        &:hover {
            background-color: #{$one-grey-50};
            border-bottom: 3px solid #{$one-green-600};
        }
    }
}

// Responsive positions
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$positions: (
  static   : static,
  absolute : absolute,
  relative : relative,
  fixed    : fixed,
  sticky   : sticky,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    // Common values
    @each $position in $positions {
      .position#{$infix}-#{$position} { position: $position !important; }
    }
  }
}

.table {
    &.table-bordered {
        thead {
            th {

            }
        }
        tbody > tr > * {
            &:first-child {
                border-left: none;
            }
            &:last-child {
                border-right: none;
            }
        }
    }
    &.table-striped {
        tbody > tr:nth-of-type(odd) > th {
            --bs-table-bg-type: #{$one-green-400};
        }
    }
    thead {
        border: none;
        * {
            border: none;
        }
        th, td {
            background-color: #{$one-green-400};
            color: #fff;
            @include font-size(20px);
            font-weight: normal;
            @include rfs(30px, padding-top);
            @include rfs(30px, padding-bottom);
        }
        th {
            border-radius: 1rem 1rem 0 0;
        }
    }
    tbody {
        th {
            background-color: #{$one-green-400};
            color: #fff;
        }
        td {
            @include font-size(18px);
            color: #{$one-grey-900};
        }
    }
}
.section-highlight {
    .table {
        --bs-table-bg: transparent;
        --bs-table-striped-bg: #fff;
    }
}

.navigation-hat {
    height: 26px;
    background-color: #{$one-grey-600};
}

@include media-breakpoint-up(lg) {
    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.primary-nav {
    .dropdown-menu {
        border-radius: 0;
        border: none;
        padding: 0;
        margin: 0;
        .dropdown-item {
            border-bottom: 1px solid #{$one-grey-200};
            padding: 10px 16px;
            background-color: #{$one-grey-50};
            &:hover {
                color: #{$one-green-400};
                background-color: #fff;
            }
        }
    }
    .nav-link {
        display: flex;
        align-items: center;
        height: 46px;
        padding-left: 0;
        padding-right: 0;
        font-size: 22px;
        color: #{$one-grey-900};
        &:hover, &.active {
            color: #{$one-green-active};
            box-shadow: #{$one-green-400} 0px 0px -4px 0px inset;
            svg.chevron {
                stroke: #{$one-green-active};
            }
        }
        &.dropdown-toggle {
            &::after {
                display: none;
            }
        }
        svg.chevron {
            width: 24px;
            height: 24px;
            margin-left: auto;
            stroke: #{$one-grey-900};
        }
    }
}
@include media-breakpoint-up(lg) {
    .primary-nav {
        // Enable hover for dropdowns on larger screens
        .dropdown:hover > .dropdown-menu {
            display: block;
            margin-top: 0; /* remove the gap so it doesn't close */
        }
        .nav-link {
            display: block;
            padding-top: 20px; //25px;
            height: 72px;
            padding-left: 25px !important;
            padding-right: 25px !important;
            color: #{$one-grey-600};
            &:hover, &.active {
                color: #{$one-green-active};
                box-shadow: #{$one-green-400} 0px -4px 0px 0px inset;
            }
            svg.chevron {
                display: none;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .nav-link .navbar-expand-lg .navbar-nav {
        padding-left: 30px;
        padding-right: 30px;
    }
}

body {
    font-family: EuclidCircularA-Light, Arial, sans-serif;
    font-weight: 400;
    color: #{$one-grey-900};
    background-color: #fff;
}

//header {
    .utils {
        display: flex;
        height: 44px;
        align-items: center;
        a, button {
            width: 24px;
            height: 24px;
        }
        .user-login {
            &:hover {
                color: #{$one-green-active};
            }
        }
    }
    .nav-header {
        background-color: #fff;
        border-bottom: 1px solid #{$one-grey-100};
        .navbar-brand {
            .logo {
                height: 44px;
            }
        }
        .offcanvas-end {
            top: 76px;
        }
        .offcanvas-backdrop {
            top: 76px;
        }
    }
//}
@include media-breakpoint-up(lg) {
    .nav-header {
        .navbar-brand {
            .logo {
                height: 56px;
            }
        }
    }
    .primary-nav {
        .nav, .navbar-nav {
            margin-bottom: -1px;
        }
    }
    .utils {
        a, svg.svg-icon {
            width: 36px;
            height: 36px;
        }
        .user-login {
            display: flex;
            width: auto;
            height: 36px;
            line-height: 36px;
            text-decoration: none;
            color: #{$one-grey-600};
            span {
                font-size: 20px;
            }
        }
    }
}

footer {
    min-height: 200px;
    padding-top: 80px;
    padding-bottom: 50px;
    background-color: #{$one-grey-900};
    color: #fff;
    .logo {
        margin-bottom: 20px;
    }
    a {
        color: #fff;
        &:hover {
            color: #{$one-grey-200};
        }
    }
    p.tagline {
        //width: 435px;
        width: 50%;
        margin-bottom: 20px;
    }
    .nav {
        margin-left: calc(0px - var(--bs-nav-link-padding-x));
        .nav-link {
            color: #fff;
            font-family: EuclidCircularA-Medium, Arial, sans-serif;
            &:hover {
                color: #{$one-grey-200};
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    footer {
        p.tagline {
            width: 35%;
        }
    }
}

@include media-breakpoint-up(xl) {
    footer {
        p.tagline {
            width: 25%;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: EuclidCircularA-Regular, Arial, sans-serif;
}

/** Home page **/
.hero-wrapper {
    background: url("../../static/images/header_background_textureB.jpg") center center repeat;
    background-size: cover;
    .container {
        height: 400px;
    }
    .hero-banner {
        width: 100%;
        color: #fff;
        h1 {
            font-size: 48px;
            line-height: 55px;
        }
        .hero-content {
            font-size: 19px;
            line-height: 29px;
        }
    }
}
@include media-breakpoint-up(md) {
    .hero-wrapper {
        .hero-banner {
            width: 50%;
        }
    }
}


/** Products pages **/
.products-subnav {
    .accordion {
        .accordion-button {
            font-size: 22px;
            border-bottom: 1px solid #{$one-grey-100};
            &.collapsed {
                color: #{$one-green-400};
            }
        }
        .accordion-body {
            padding: 0;
        }
    }
}
@include media-breakpoint-down(md) {
    //.page-header {
    //    margin-bottom: 0;
    //}
    .products-subnav {
        &.container {
            --bs-gutter-x: 0;
        }
        .nav-tabs {
            flex-direction: column;
            .nav-link {
                background-color: #{$one-grey-100};
                border-bottom: 1px solid #{$one-grey-200};
                margin-bottom: 0;
                &.active {
                    color: #{$one-green-400};
                    background-color: #fff;
                }
                :hover {
                    background-color: #fff;
                    border-bottom: 1px solid #{$one-grey-200};
                }
            }
        }

    }
}

@include media-breakpoint-down(lg) {
    .products-subnav {
        .nav-tabs .nav-item {
            font-size: 16px;
        }
    }
}

/** Media articles **/
body {
    &.media-landing {
        .latest-article {
            .feature-image {
                img {
                    object-fit: cover;
                    min-height: 300px;
                    max-height: 500px;
                    vertical-align: middle;
                }
            }
        }
    }
    &.media-article {
        .page-content {
            .back-to-media {
                a {
                    color: #{$one-grey-900};
                }
            }
            .article-headline {
                font: 51px / 57px EuclidCircularA-Regular, Arial, sans-serif;
            }
            .article-subtitle {
                font: 22px / 24px EuclidCircularA-Light, Arial, sans-serif;
            }

            .article-published-date {
                margin-bottom: 2rem;
                color: #{$one-grey-400};
            }
        }
    }
}
/** Pages common **/
.page-header {
    background-color: #{$one-grey-600};
    &.textured {
        background: url("../../static/images/header_background_textureB.jpg") center top repeat;
        background-size: cover;
        .container {
            text-align: left;
        }
    }
    &.error {
        margin-bottom: 25rem;
    }

    body.media.media-article & {
        img {
            object-fit: cover;
            min-height: 300px;
            max-height: 500px;
            vertical-align: middle;
        }
    }

    .container {
        height: 175px;
        text-align: center;
        h1 {
            color: #fff;
            font-family: EuclidCircularA-Medium, Arial, sans-serif;
            font-weight: 500;
        }
        vertical-align: middle;
    }
}
@include media-breakpoint-up(md) {
    .page-header {
        .container {
            height: 205px;
        }
    }
}
@include media-breakpoint-up(lg) {
    .page-header {
        .container {
            height: 260px;
        }
    }
}
.page-feature {
    .feature-item {
        background-color: #{$one-grey-50};
        padding: 20px 16px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        height: 100%;
        &:hover {
            box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
        }
        .feature-icon {
            width: 48px;
            height: 48px;
            margin-bottom: 10px;
        }
        .feature-image {
            margin-bottom: 10px;
            img {
                border-radius: 10px;
                width: 100%;
                //height: 120px;
            }
        }
        .feature-content {
            display: flex;
            flex-flow: column;
            flex-grow: 1;

            .feature-description {
                display: flex;
                flex-direction: column;
                height: 100%; // disabled this for initial simplified website
            }

            a.link-start-icon {
                color: #{$one-green-600};
                background: url('../../static/icons/link-external.svg') left center no-repeat;
                //background: url('data:image/svg+xml;utf8,<svg class="icon-link-external"><use xlink:href="#icon-link-external" /></svg>') left center no-repeat;
                background-size: contain;
                padding-left: 24px;
                display: inline-block;
                font-family: EuclidCircularA-Medium, Arial, sans-serif;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.content-module {
    /*h2 {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 14px;
    }*/
    /*.intro {
        font-size: 19px;
        line-height: 29px;
    }*/
}
.section-highlight {
    background-color: #E6FDEE; //#{$one-green-50};
}
.svg-icon {
    width: 24px;
    height: 24px;
    stroke: #{$one-grey-900};

    &.admin-system {

    }

    &.menu-system {

    }
}
.img-placeholder {
    min-width: 600px;
    min-height: 400px;
}

ol.legal {
    counter-reset: item;
    padding-left: 0;

    & > li {
        margin-top: 2rem;
        margin-bottom: 2rem;
        & > b {
            font-family: EuclidCircularA-Medium, Arial, sans-serif;
            display: inline-block;
            margin-bottom: 1rem;
        }
        & > * {
            padding-left: 1rem;
        }
    }

    li {
        display: block;

        &::before {
            content: counters(item, ".") ". ";
            counter-increment: item;
            font-family: EuclidCircularA-Medium, Arial, sans-serif;
            font-weight: bold;
            display: inline-block;
        }
        > p {
            &.top-level {
                margin-left: -1rem;
            }
        }
    }

    ol {
        counter-reset: item;
        margin-bottom: 1rem;

        & > li {
            margin-bottom: 1rem;

            &::before {
                content: counters(item, ".") " ";
                counter-increment: item;
                display: inline-block;
                margin-right: 2rem;
                font-weight: normal;
            }
        }
    }
}

ul.list-spaced {
    li {
        margin-bottom: 0.8rem;
    }
}

.infrastructure-tiles {
    background-color: #{$one-grey-100};
}

.img-enlarger {
    &:before {
        display: none;
        content: "larger";
        position: absolute;
        color: red;
        z-index: 500;
        //background-color: green;
        width: 140px;
        height: 140px;
        //background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%20160%20160%22%20fill%3D%22none%22%3E%3Ccircle%20cx%3D%2260%22%20cy%3D%2260%22%20r%3D%2256%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3C/circle%3E%3Cline%20x1%3D%2299.6%22%20y1%3D%2299.6%22%20x2%3D%22156%22%20y2%3D%22156%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3C/line%3E%3C/svg%3E");
        background-image: url("data:image/svg+xml,%3Csvg viewBox%3D%220 0 160 160%22 fill%3D%22none%22%3E%3Ccircle cx%3D%2260%22 cy%3D%2260%22 r%3D%2256%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22%3E%3C/circle%3E%3Cline x1%3D%2299.6%22 y1%3D%2299.6%22 x2%3D%22156%22 y2%3D%22156%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22%3E%3C/line%3E%3C/svg%3E");
        //background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
        //background-size: contain;
    }
    &:hover {
        &:before {
            background-color: rgba(255, 255, 0, 0.4);
        }
    }
}

.TODO {
    background-color: lime;
  &::before {
      content: 'TODO:';
      vertical-align: super;
      font-size: 0.5rem;
      font-weight: bold;
      position: absolute;
      margin-top: -0.2rem;
  }
}